
import Component, { ComponentMethods } from "@/module/component/component"
import Request from "@/module/request/request"
import Utils from "@/module/utils/utils"
import Message from "@/module/interactive/message"

import elemIcon from "@/components/elem-icon.vue"
import elemForm from "@/components/elem-form.vue"
import Href from "@/module/config/href"

class FormView extends ComponentMethods implements ComponentEntity {
    private formConfig: obj = {}

    private value: obj = null

    private type: "create" | "edit" | "config" = "create"

    private step_map: obj = {}

    public components = {
        elemIcon,
        elemForm,
    }

    async onLoad(param: obj): Promise<void> {
        this.formConfig = {}
        this.value = null

        const name = (this.entity_name = param.name)
        const type = (this.type = param.type)
        const config = (this.pageConfig = await Href.getPage(name))

        if (config.type === "config") {
            this.configName = name

            Request.get<obj>(`ADMIN://Setting/Config/GetCreateData`, { name: name }).then(async res => {
                // 设置标题
                this.setTitle(config.title, "config")

                this.value = await Request.get<obj>(`ADMIN://Setting/Config/GetData`, { name: name })

                this.formConfig = res
            })
        } else {
            Request.get<obj>(`ADMIN://${name}/GetCreateData`).then(async res => {
                // 设置标题
                this.setTitle(config.title)

                // 是否为编辑模式
                if (type === "edit") {
                    // 获取实体
                    this.value = await Request.get<obj>(`ADMIN://${name}/FindEntity`, { uuid: param.i })
                }

                this.formConfig = res
            })
        }
    }

    onChangeValue(evt: ElemEvent<obj>) {
        this.value = Utils.copy(evt.value)
    }

    /** 监听滚动到指定模块 */
    onScrollToStepBox(id: string): void {
        Utils.getElements<HTMLDivElement>(["#StepBox", "#" + id], (b, i) => {
            b.scrollTo({
                top: i.offsetTop - 80,
                left: 0,
                behavior: "smooth",
            })
        })
    }

    /**
     * 获取序号
     */
    getStepNumber(idx: number) {
        const es = Utils.getElementAll(".step-base")

        for (let i = 0, leng = es.length; i < leng; i++) {
            if (es[i].classList.contains(".step-item-" + idx)) {
                return i + 1
            }
        }

        return idx + 1
    }

    async submit() {
        const status = await Utils.getFormData("#CreateForm")

        if (status.isBlank()) {
            return Message.error(status.getMessage())
        }

        const data = {
            ...this.getParams(),
            ...status.getData(),
        }

        let url: string

        if (this.pageConfig.type === "config") {
            data.configName = this.configName
            // 配置文件保存路径
            url = "ADMIN://Setting/Config/Save"
        } else {
            url = `ADMIN://${this.entity_name}/Save`
        }

        if (this.type === "edit") {
            data.uuid = this.value.uuid
        }

        Request.post(url, data, {
            json: true,
        }).then(() => {
            Message.success(this.getTypeName(), true)
                .onTimeout(() => {
                    // 返回上一步
                    if (this.type !== "config") {
                        this.onBack()
                    }
                })
                .build()
        })
    }

    getTypeName() {
        switch (this.type) {
            case "create":
                return "添加成功"
            case "edit":
                return "修改成功"
            case "config":
                return "配置成功"
        }
    }

    private getStepMap(name: string): obj[] {
        if (!this.step_map[name]) {
            let size = this.value && this.value[name] && this.value[name].length > 0 ? this.value[name].length : 1
            var arr = []

            for (let i = 0; i < size; i++) {
                arr.push({
                    key_id: Utils.getUuid(),
                })
            }

            this.step_map[name] = arr
        }

        return this.step_map[name]
    }

    private onChangeList(type: "append" | "clear", name: string, idx?: number) {
        const step: obj[] = this.step_map[name]

        if (type === "append") {
            step.push({
                key_id: Utils.getUuid(),
            })
        } else {
            step.splice(idx, 1)
        }
    }

    getConditionValue(where: string) {
        if (!where) return true
        return new Function(`return ${where.replace(/&{(\w*)}/g, "this.$1")}`).call(this.value)
    }
}

export default Component.build(new FormView())
